export const getTaxFromOffer = (offer, currentQuantity) => {
  const newOffer = offer
    ?.map(tax => ({
      ...tax,
      taxTotalValue: tax?.taxValue * currentQuantity,
    }))
    ?.find(tax => tax?.taxId?.toUpperCase() === 'ICMSST');
  return newOffer;
};

export const closureSetCurrentQuantity = (setCurrentQuantity, quantity) => () => setCurrentQuantity(quantity);
