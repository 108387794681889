import React, { useState } from 'react';
import styled from 'styled-components';
import Tooltip from '@catalogo/theme-tooltip-box';
import Profile from '@catalogo/ui-portal-desktop-svg/profile.svg';
import TooltipLogin from './components/tooltip-login';
import { Logged, LoggedOut } from './components/login';

export const Login = ({ cookies = {}, config, history }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { prtlNick } = cookies;

  return (
    <Container $isLogged={!!prtlNick}>
      <Wrapper
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        zIndex={showTooltip ? 9 : 0}
      >
        <ProfileUI width="32px" height="36px" />
        {!prtlNick ? <LoggedOut /> : <Logged profile={prtlNick} />}
      </Wrapper>
      <Tooltip
        width="auto"
        height="auto"
        show={showTooltip}
        positionType="absolute"
        color="#fff"
        arrowSize={12}
        marginTop={0}
        arrowPosition={!prtlNick ? '71%' : '58%'}
        top="60px"
        right="-57px"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        zIndex={showTooltip ? 9 : 0}
      >
        {showTooltip && (
          <TooltipLogin isLogged={prtlNick} zIndex={showTooltip ? 9 : 0} history={history} config={config} />
        )}
      </Tooltip>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  position: relative;
  text-decoration: none;
  color: #e0e2f0;
  display: flex;
  height: 40px;
  align-items: center;
  flex: 1;
  min-width: ${({ $isLogged }) => ($isLogged ? '180px' : '240px')};
  max-width: ${({ $isLogged }) => ($isLogged ? '180px' : '240px')};
`;

const Wrapper = styled.div`
  display: flex;
  text-decoration: none;
  width: auto;
  cursor: default;
  position: relative;
  height: 80px;
  align-items: center;
  white-space: nowrap;
  transition: all 0.3s;
  z-index: ${({ zIndex }) => zIndex};
  max-width: 100%;
`;
const ProfileUI = styled(Profile)`
  fill: #fff;
`;
