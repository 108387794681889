import React, { useState, useEffect } from 'react';
import compose from 'lodash/fp/compose';
import { useQuery } from '@apollo/client';
import { withCookies } from '@catalogo/core-connect/cookies';
import { getVibraVariablesToTax } from '@catalogo/core-vibra';
import { getSellerId } from '@catalogo/core-consumption-type';
import { fmtProductWithTax } from '@catalogo/core-b2b-product-tax';
import { getB2BTax } from '../queries/get-b2btax.graphql';
import { closureSetCurrentQuantity, getTaxFromOffer } from '../helpers/cart-lines';

export const withCartLine = Component => props => {
  const { id, quantity, salesPrice: salesPriceProp, cookies } = props;
  const [currentQuantity, setCurrentQuantity] = useState(quantity);
  const { isVibra, region, warehouse } = getVibraVariablesToTax(cookies);
  const sellerId = getSellerId(cookies);

  useEffect(closureSetCurrentQuantity(setCurrentQuantity, quantity), [quantity]);

  const { data, loading } = useQuery(getB2BTax, {
    variables: {
      productId: id,
      region,
      warehouse,
      sellerId,
    },
    skip: !isVibra,
  });

  const productData = data?.product;
  const product = productData ?? {
    offers: {
      result: [{ salesPrice: salesPriceProp }],
    },
  };

  const productWithTax = fmtProductWithTax(product);
  const offerWithTaxes = productWithTax?.offers?.result?.[0];
  const tax = getTaxFromOffer(offerWithTaxes?.b2bTax);

  const [salesPrice, salesPriceTaxless] = [offerWithTaxes?.salesPrice, offerWithTaxes?.salesPriceTaxless];
  const formattedProduct = {
    id,
    tax,
    currentQuantity,
    salesPrice,
    salesPriceTaxless,
    total: isVibra ? salesPrice * currentQuantity : salesPrice,
    totalTaxless: isVibra ? salesPriceTaxless * currentQuantity : salesPriceTaxless,
  };

  return <Component {...props} formattedProduct={formattedProduct} isLoading={loading} />;
};

export default compose(withCookies, withCartLine);
