import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import QuestionIcon from '@catalogo/ui-americanas-desktop-svg/questionmark.svg';
import { setConsumptionType } from '@catalogo/service-empresas-header/src/helpers/tooltip-consumption-type';

const ToolTipConsumptionType = ({ history, setCookie, zIndex, routeType }) => {
  const handleAcceptCookies = type => setConsumptionType(history, setCookie, type, routeType);

  return (
    <Container zIndex={zIndex}>
      <Link onClick={handleAcceptCookies('RESALE')}>somente revenda</Link>
      <Link onClick={handleAcceptCookies('OWN')}>somente uso consumo</Link>
      <InfoLink to="/hotsite/empresas-site-finalidade">
        <QuestionIcon width={24} height={24} />
        entenda a diferença entre as finalidades
      </InfoLink>
    </Container>
  );
};

export default ToolTipConsumptionType;

const Container = styled.div`
  color: #666;
  margin: 26px 30px;
  z-index: ${({ zIndex }) => zIndex};
`;

const Link = styled(Nav)`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #666;
    fill: #666;
    padding-bottom: 20px;
    transition: all 0.4s ease;

    & svg {
      margin-right: 8px;
    }

    &:hover {
      color: ${theme.secondaryColor};
      fill: ${theme.secondaryColor};
    }
  `}
`;

const InfoLink = styled(Link)`
  border-top: 1px solid #e8e8e8;
  padding: 20px 0 0 0;
`;
