import { useMemo } from 'react';
import { getImage } from '@catalogo/service-americanas-common/src/helpers/image';

export const fmtDepartmentsData = departmentsData =>
  departmentsData.map(props => {
    const { _id, ...rest } = props;
    if (props?.children?.length > 0) {
      return {
        ...rest,
        id: _id,
        visible: false,
        children: fmtDepartmentsData(props.children),
      };
    }
    return { ...rest, id: _id, visible: false };
  });

export const titleIsDepartment = ({ title }) => title === 'departamentos';

export const titleIsAllDepartments = ({ title }) => title === 'todos os departamentos';

export const titleAlsoCheck = ({ title }) => title === 'confira também :)';

export const dispatchMetric = dispatchMetrics => (category, label) => () =>
  dispatchMetrics('event:track', { category, label, action: 'click' });

export const useReturnMemo = (fn, arrayProps) => useMemo(() => fn(...arrayProps), [...arrayProps]);

export const getImageData = menu => ({
  image: menu?.component?.image,
  imageUrl: menu?.component?.imageUrl,
  alt: menu?.component?.alt,
});

export const getImageBanner = (props, position) => ({
  image: getImage(props?.[position]?.component?.children?.[0], 'small'),
  alt: props?.[position]?.component?.children?.[0]?.alternateText,
  link: props?.[position]?.component?.children?.[0]?.linkUrl,
});
