import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const TrendingDepartmentsItem = ({ item, highlight, onMouseEnter, onMouseLeave, dispatchMetrics }) => (
  <Item
    highlight={highlight}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={() =>
      dispatchMetrics('event:track', {
        event: 'c:click',
        action: 'click',
        category: 'menu-tts',
        label: item?.title,
      })
    }
  >
    <Link to={item?.link} highlight={highlight}>
      {item?.title}
    </Link>
  </Item>
);

export default TrendingDepartmentsItem;

const Item = styled.div`
  ${({ highlight }) => css`
    display: inline-block;
    position: relative;
    color: #35002a;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    cursor: pointer;
    height: 100%;

    ${highlight &&
    css`
      &::after {
        content: '';
        background-color: ${({ theme }) => theme.primaryColor};
        border-radius: 16px;
        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;
        width: auto;
        height: 2px;
        z-index: 2;
        pointer-events: none;
      }
    `}
  `}
`;

const Link = styled(Nav)`
  ${({ highlight, theme }) => css`
    padding: 15px 12px;
    display: block;

    color: #35002a;
    text-decoration: none;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: none;
    }
    ${highlight &&
    css`
      color: ${theme.primaryColor} !important;
    `}
  `}
`;
