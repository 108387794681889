import { useQuery, useLazyQuery } from '@apollo/client';
import { getHeaderPortal, getHeaderPortalMenu } from '../queries/header.graphql';

export const useHeader = (variables, skip) => {
  const { loading, data, error } = useQuery(getHeaderPortal, {
    variables,
    skip,
  });

  return { loading, data, error };
};

export const useMenu = () => {
  const [fetchMenu, { loading, data, error }] = useLazyQuery(getHeaderPortalMenu);

  return { fetchMenu, loading, data, error };
};
