import { getCartCookies } from '@catalogo/service-empresas-cart/src/helpers/cart';

export const getCartCookieNameDefault = config => {
  const prefix = { portal: 'prtl', aemp: 'aemp' };

  return `${prefix[config?.brand]}.cart.id`;
};

export const getCartCookieNameWithConsumptionType = (config, cookies) => {
  const types = {
    OWN: `${getCartCookieNameDefault(config)}.own`,
    RESALE: `${getCartCookieNameDefault(config)}.resale`,
  };

  const consumptionType = cookies?.['consumptionType'];

  return types[consumptionType];
};

export const setInitialCartId = ({ config, setCookie, cookies, refetchCart }) => cartResult => {
  const defaultCartIdCookieKey = getCartCookieNameDefault(config);
  const cartIdCookieWithConsumptionType = getCartCookieNameWithConsumptionType(config, cookies);
  const cardIdFromCookies = cookies?.[cartIdCookieWithConsumptionType];
  const cartIdFromResult = cartResult?.addCartLines?.cartId;

  if (
    (!cardIdFromCookies && !!cartIdFromResult) ||
    (cardIdFromCookies && !!cartIdFromResult && cartIdFromResult !== cardIdFromCookies)
  ) {
    setCookie(defaultCartIdCookieKey, cartIdFromResult);
    setCookie(cartIdCookieWithConsumptionType, cartIdFromResult);
  }

  refetchCart();
};

export const addProduct = ({ addProductQuery, updateProductQuery, cartId, cartLine, setIsUpdateAction, cookies }) => ({
  offerId,
  quantity,
}) => {
  const cookiesParams = getCartCookies(cookies);

  if (cartLine) {
    setIsUpdateAction(true);
    updateProductQuery({
      variables: { cartId, lineId: cartLine?.id, item: { offerId, quantity }, ...cookiesParams },
    });
  } else {
    setIsUpdateAction(false);
    addProductQuery({
      variables: { cartId, lines: [{ offerId, quantity }], ...cookiesParams },
    });
  }
};
