import config from '@catalogo/core-configuration/config/out/config.server.json';
import { ICookies } from './types';

export const isResale = (cookies: ICookies): boolean => cookies?.consumptionType?.toLowerCase() === 'resale';

/* istanbul ignore next */
export const getSellerId = (cookies: ICookies, sellerId = ''): string =>
  isResale(cookies) || config?.brand === 'portal' ? config?.sellerId : sellerId;

export const getContext = (cookies: ICookies): string => `empresas_${isResale(cookies) ? 'revenda' : 'usoconsumo'}`;
