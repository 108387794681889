import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import compose from 'lodash/fp/compose';
import { withConfig } from '@catalogo/core-connect/config';
import { withCookies } from '@catalogo/core-connect/cookies';
import { closureAcceptCookies, renderOnce } from './helpers';

const Container = styled.div`
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 25px;
  z-index: 9999;
  align-items: center;
  max-width: 1230px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px #aaa;
  border-radius: 12px;
  ${({ $open }) => css`
    display: ${$open ? `flex` : `none`};
  `};
`;

const Content = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.secondaryColor};
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: initial;
  line-height: 20px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.primaryColor};
  white-space: nowrap;
`;

const Button = styled.button`
  background: #fff 0 0 no-repeat padding-box;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  padding: 10px 20px;
  letter-spacing: 0;
  cursor: pointer;
  white-space: nowrap;
  ${({ theme }) => css`
    border: 1px solid ${theme.primaryColor};
    color: ${theme.primaryColor};
    text-transform: ${theme.btnTextTransform};
  `};
`;

export const LgpdMessageBox = ({ cookies, setCookie, config }) => {
  const lgpdCookie = config?.cookiesKey?.lgpd;
  const [open, setOpen] = useState(!cookies[lgpdCookie]);

  const handleAcceptCookies = closureAcceptCookies(setOpen, setCookie, lgpdCookie);

  return (
    <Container $open={open}>
      <Content>
        <strong>Americanas Empresas e os cookies:</strong> a gente usa cookies para personalizar anúncios e melhorar a
        sua experiência no site. Ao continuar navegando, você concorda com a nossa&nbsp;
        <Link href="/hotsite/politica-de-privacidade" target="_blank" rel="noreferrer">
          Política de Privacidade
        </Link>
        .
      </Content>
      <Button onClick={handleAcceptCookies}>continuar e fechar</Button>
    </Container>
  );
};

export default compose(withConfig, withCookies)(renderOnce(LgpdMessageBox));
