import { fmtOfferWithTaxes } from '@catalogo/core-offer';

export const fmtProductWithTax = product => {
  const hasInternalProductKey = !!product?.product;
  const productToAddTax = hasInternalProductKey ? product?.product : product;
  const hasOffers = !!productToAddTax?.offers;

  if (hasOffers) {
    const productWithTax = {
      ...productToAddTax,
      offers: {
        ...productToAddTax.offers,
        result: productToAddTax.offers?.result?.map(fmtOfferWithTaxes),
      },
    };

    return hasInternalProductKey ? { product: { ...productWithTax } } : productWithTax;
  }

  return product;
};

export const fmtProductsWithTax = products => products?.map(fmtProductWithTax);
