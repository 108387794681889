import React, { useEffect } from 'react';
import compose from 'lodash/fp/compose';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withConfig } from '@catalogo/core-connect/config';
import { withMetrics } from '@catalogo/core-metrics';
import { withDevice } from '@catalogo/core-connect/device';
import { parsedQsEffect } from '@catalogo/service-empresas-header/src/helpers/header';
import { useHeaderContext } from '../context/header';
import { dispatchMetric } from '../helpers/header';
import { useHeader, useMenu } from '../hooks/header';

export const withHeader = Component => props => {
  const { config, match, parsedQs, history, cookies, dispatchMetrics, route } = props;
  const term = match?.params?.term || parsedQs?.conteudo;
  const rc = parsedQs?.rc;
  const { isFixed } = useHeaderContext();

  const variables = {
    sitepage: 'portalb2b/header-v2',
  };

  const { data: headerData } = useHeader(variables);
  const { data: menuData, loading: loadingMenu, fetchMenu } = useMenu();

  useEffect(parsedQsEffect({ parsedQs, history, routeType: route?.type }), [parsedQs]);

  const values = {
    dispatchMetric: dispatchMetric(dispatchMetrics),
    searchTerm: rc || term?.replace(/-/g, ' '),
    history: history,
    cookies: cookies,
    config: config,
    isFixed,
    fetchMenu,
    variables,
    loadingMenu,
    ...props,
    ...headerData,
    ...menuData,
  };

  return <Component {...values} />;
};

export default compose(withMetrics, withConfig, withCookies, withDevice, withHeader);
