export const cartEffect = ({ products, productsWithTax, setProductsWithTax, isVibra }) => () => {
  if (isVibra) {
    const productIds = products?.map(({ id }) => id);
    const currentProducts = productsWithTax?.filter(({ id }) => productIds?.includes(id));
    if (productsWithTax?.length > productIds?.length) {
      setProductsWithTax(currentProducts);
    }
  }
};

export const cartProductEffect = ({
  isLoading,
  productsWithTax,
  formattedProduct,
  setProductsWithTax,
  isVibra,
}) => () => {
  if (isVibra && !isLoading) {
    const hasProductInList = productsWithTax?.find(({ id }) => id === formattedProduct?.id);
    if (hasProductInList) {
      const updatedList = productsWithTax?.filter(({ id }) => id !== formattedProduct?.id);
      setProductsWithTax([...updatedList, formattedProduct]);
    } else {
      setProductsWithTax([...productsWithTax, formattedProduct]);
    }
  }
};
