import React from 'react';
import styled from 'styled-components';
import ArrowSvg from '@catalogo/ui-americanas-desktop-svg/arrow.svg';

export const Logged = ({ profile }) => (
  <Text $isLogged={true}>
    {profile ? <NickName>{decodeURI(profile).toUpperCase()}</NickName> : 'olá, faça seu login'}
    <Span>
      minha conta
      <ArrowUI fill="#35002a" width="10px" height="6px" />
    </Span>
  </Text>
);

export const LoggedOut = () => (
  <Text $isLogged={false}>
    olá, faça seu login <br />{' '}
    <Span>
      ou cadastre sua empresa
      <ArrowUI fill="#35002a" width="10px" height="6px" />
    </Span>
  </Text>
);

const Text = styled.div`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({ $isLogged }) => ($isLogged ? '136px' : '196px')};
`;

const NickName = styled.span`
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ArrowUI = styled(ArrowSvg)`
  margin-left: 5px;
  fill: #e0e2f0;
`;

const Span = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
`;
