import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import WishlistIcon from '@catalogo/ui-empresas-desktop-svg/icon-wishlist-header.svg';
import ShoppingBasketIcon from '@catalogo/ui-portal-desktop-svg/header-basket-desk.svg';
import Tooltip from '@catalogo/theme-tooltip-box';
import withCart from '@catalogo/service-portal-header/src/hocs/cart';
import { useDebounce } from '@catalogo/core-hooks/debounce';
import { Cart } from './cart';

export const IconMenu = props => {
  const [showTooltip, setShowTooltip] = useState(false);

  const debouncedTooltip = useDebounce(showTooltip, 500);
  const { cartSize, products, total, cookies } = props;

  return (
    <IconMenuContainer>
      <FavoriteLink title="minha lista de compras" href="/listas/favoritos">
        <WishlistIconUI fill="#fff" />
      </FavoriteLink>
      <ShoppingBasketLink>
        <ShoppingBasketWrapper zIndex={showTooltip ? 9 : 0} showTooltip={showTooltip}>
          <ShoppingBasketIconUI onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />
          <BagdeBasketItems>{cartSize}</BagdeBasketItems>
        </ShoppingBasketWrapper>
        <Tooltip
          width="fit-content"
          show={debouncedTooltip}
          positionType="absolute"
          color="#fff"
          arrowSize={10}
          arrowPosition="87%"
          zIndex={9}
          top="50px"
          right="-20px"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <Cart products={products} total={total} cookies={cookies} />
        </Tooltip>
      </ShoppingBasketLink>
    </IconMenuContainer>
  );
};

export default withCart(IconMenu);

const IconMenuContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  padding: 0 12px 0 12px;
  flex: 1;
`;

const WishlistIconUI = styled(WishlistIcon)`
  width: 36px;
  height: 36px;
  transition: all 0.3s;
`;

const FavoriteLink = styled.a`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 12px;
  cursor: pointer;
  &:hover ${WishlistIconUI} {
    transform: scale(1.25);
  }
`;

const ShoppingBasketLink = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 24px;
`;

const ShoppingBasketWrapper = styled.span`
  cursor: pointer;
  transition: z-index 0.3s;
  z-index: ${({ zIndex }) => zIndex};

  ${({ showTooltip }) =>
    !showTooltip &&
    css`
      transition: all 0.3s;
      transition-delay: 1s;
    `}

  &:hover {
    div {
      animation: pop 0.6s;
      animation-iteration-count: 1;
    }
  }

  @keyframes pop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const ShoppingBasketIconUI = styled(ShoppingBasketIcon)`
  width: 36px;
  height: 36px;
  position: 'relative';
  fill: #fff;
`;

const BagdeBasketItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.red.dark};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-family: 'Roboto', sans-serif;
  top: -5px;
  right: -8px;
  pointer-events: none;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.3);
  }
`;
